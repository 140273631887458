import React from 'react';

import { Section, Text, Button } from '@utils';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

const NotFound: React.FC = () => {
  return (
    <>
      <GatsbySeo noindex nofollow title="Page not found" />
      <Section id="notFound" className="flex-1">
        <div className="flex flex-col items-center justify-center space-y-6">
          <Text style="h1" tag="h1">
            404
          </Text>
          <Text>The page you were looking for wasn't found!</Text>
          <Button type="internal" variant="primary" href="/">
            Return Home
          </Button>
        </div>
      </Section>
    </>
  );
};

export default NotFound;
